<template>
	<div
		class="position-fixed-full size-px-14" style="z-index: 999"
	>
		<div
			class="position-relative-full bg-white flex-column " style="z-index: 998"
		>
			<div class="full-height overflow-y-auto pa-20 text-center mt-50">
				<div class=" character2 pt-150">
					<h3>{{ cartel_info.cartl_name }}</h3>
				</div>
				<div
					class="mt-20 bg-gray-light radius-10 pa-20"
				>
					<h4 class="color-blue">{{ result_info.subscrp_plan_name }}</h4>
					<div>{{ $language.subscribe.txt_subscribe_complete }} <!-- 구독이 완료되었습니다. --></div>
				</div>
			</div>
			<div
				class="mt-auto "
			>
				<div class="mt-20 justify-space-between-in pa-20">
					<div class="">
						<div class="color-333">{{ $language.subscribe.txt_subscribe_complete }} <!-- 구독 플랜 --></div>
						<div class="font-weight-500">{{  result_info.subscrp_plan_name }}</div>
					</div>
					<div class="mt-10">
						<div class="color-333">{{ $language.point_result.title_payment_type }} <!-- 결제 수단 --></div>
						<div class="font-weight-500">{{  result_info.payment_mthd_name }}</div>
					</div>
					<div class="mt-10">
						<div class="color-333">{{ $language.subscribe.txt_subscribe_complete }} <!-- 결제 금액 --></div>
						<div class="font-weight-700 color-red">{{  result_info.tot_vat_price ? result_info.tot_vat_price : result_info.coin_token_quantity ? result_info.coin_token_quantity : result_info.point ? result_info.point : '' | makeComma }} <template v-if="result_info.tot_vat_price">원</template></div>
					</div>
					<div class="mt-10">
						<div class="color-333">{{ $language.subscribe.txt_subscribe_period }} <!-- 구독 기간 --></div>
						<div class="font-weight-500">{{  result_info.subscrp_startdt }} ~ {{ result_info.subscrp_enddate}}</div>
					</div>
				</div>
				<div
					class="btn_area"
				>
					<button
						class="btn_l btn_fill_blue"
						style="border-radius: unset"

						@click="toCartel"
					>{{ $language.point_result.btn_confirm }} <!-- 확인 --></button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CartelSubscribeResult'
		, props: ['user', 'result_info', 'cartel_info']
		, methods: {
			toCartel: function(){

				this.$bus.$emit('to', { name: 'mafia049', params: { idx: this.$route.params.idx }})
			}
		}
	}
</script>